import { Grid } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useAliases } from '../../../../api/alias/hooks';
import { DataValue } from '../../../../api/types';
import { toRegularMetricResultOrError, toSegmentation } from '../../../../api/utils';
import { TableViewMetrics } from '../../../../common/components/tableview/TableViewMetrics';
import { getFilterConditions, mergeSegmentations } from '../../../../common/utils';
import { UNDEFINED_DISPLAY_KEY } from '../../../../constants';
import {
  useAliasServiceContext,
  useGlobalLocaleContext,
  useLatestDomainPreferencesContext,
  useMetricDetailsMapContext,
  useMetricServiceContext,
} from '../../../../context/contexts';
import { DataFieldWithDataType, RegularMetricIdType, TimeSelection } from '../../../../types';
import { CHART_CONTENT_HEIGHT, CHART_TITLE_HEIGHT } from '../../../styles';
import { MetricTypes } from '../../../types';
import { BarChartComponentConfig } from '../../dashboards/types';
import { formatHierarchicalLabel } from '../../filter/utils-display';
import { ChartComponentProps } from '../chart-types';
import { ChartDataFetchError } from '../ChartDataFetchError';
import { ChartTitle } from '../ChartTitle';
import { BarChart } from '../mui-charts';
import { toMuiChartsTimePeriodData } from '../mui-charts/utils-display';
import { useDisplay } from './hooks';
import { chartStyles } from './styles';
import { Tools } from './Tools';
import { GlobalTimePeriodDisplayAction, ToolsAction } from './types';
import { timeSliderStateToTimePeriodTimeSelection } from './utils';

type ChartTimePeriodProps = ChartComponentProps<
  BarChartComponentConfig,
  | 'manualDataRefetchCountHandle'
  | 'timeSliderState'
  | 'filtersState'
  | 'segmentationState'
  | 'showTools'
  | 'fullScreenHandle'
  | 'displayGlobalState'
>;

export const ChartTimePeriod = (props: ChartTimePeriodProps) => {
  const {
    componentConfig,
    manualDataRefetchCountHandle,
    timeSliderState,
    filtersState,
    segmentationState,
    showTools,
    fullScreenHandle,
    displayGlobalState,
  } = props;
  const { t } = useTranslation();
  const metricService = useMetricServiceContext();

  const { title, queries, chartTypeConfig, colors } = componentConfig;
  const { metrics, timeSelection, filters, segmentations } = queries[0]; // TODO: make it work for multiple queries
  const finalTimeSelection: TimeSelection = timeSliderState
    ? timeSliderStateToTimePeriodTimeSelection(timeSliderState)
    : timeSelection;

  const allSegmentations = mergeSegmentations(
    segmentations,
    toSegmentation(segmentationState.items, MetricTypes.REGULAR),
    metrics,
    2
  );

  const displayHandle = useDisplay(chartTypeConfig, allSegmentations, metrics, displayGlobalState);
  const [displayState, displayReducer] = displayHandle;

  useEffect(() => {
    const action: GlobalTimePeriodDisplayAction = {
      type: 'global-state-sync',
      globalDisplayState: displayGlobalState,
    };
    displayReducer(action);
  }, [displayGlobalState]);

  useEffect(() => {
    // This is necessary when segmentation is added by the user
    // resulting in a stacked bar chart
    const action: ToolsAction = {
      type: 'toggle-tools',
      segmentations: allSegmentations,
    };
    displayReducer(action);
  }, [segmentationState.items.length]);

  const allFilters =
    [getFilterConditions(filtersState.items, MetricTypes.REGULAR, false), filters]
      .flatMap((f) => (f ? [f] : []))
      .join(' AND ') || undefined;

  const [queryKey] = useDebounce(
    JSON.stringify([
      'chart-time-period',
      metrics,
      finalTimeSelection,
      allFilters,
      allSegmentations,
      manualDataRefetchCountHandle.current,
      displayState.selectedSortOrder,
    ]),
    500
  );

  const metricDetailsMap = useMetricDetailsMapContext();
  const aliasService = useAliasServiceContext();
  const domainPreferences = useLatestDomainPreferencesContext();
  const { data: aliases } = useAliases(aliasService);
  const locale = useGlobalLocaleContext();
  const getAliasForMetric = aliasService.getAliasForMetricGroupId(aliases, locale.selected);
  const formatLabel = (value: DataValue[], dataField: DataFieldWithDataType) =>
    formatHierarchicalLabel(value, dataField, t(UNDEFINED_DISPLAY_KEY), t);

  // I haven't supported employeeCohortMetrics in time period charts yet
  const { data, error } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return toRegularMetricResultOrError(
        metricService.queryRegularMetrics,
        metrics as RegularMetricIdType[],
        finalTimeSelection,
        allFilters,
        allSegmentations
      );
    },
  });
  const { series, tableViewData, barLabelConfig, metricSql, xAxisConfig, yAxisConfig } = toMuiChartsTimePeriodData(
    data,
    allSegmentations,
    displayState,
    metricDetailsMap,
    formatLabel,
    getAliasForMetric,
    colors,
    domainPreferences.settings,
    t
  );

  if (error) {
    return <ChartDataFetchError manualDataRefetchCountHandle={manualDataRefetchCountHandle} />;
  }

  return (
    <Grid item container direction="column" sx={{ height: '100%' }}>
      <Grid item container sx={{ height: CHART_TITLE_HEIGHT }}>
        <ChartTitle
          metrics={metrics}
          metricsSql={metricSql}
          title={title?.[locale.selected]}
          timeSelection={finalTimeSelection}
          segmentations={allSegmentations}
          userFilters={allFilters}
        />
        {showTools ? <Tools fullScreenHandle={fullScreenHandle} displayHandle={displayHandle} /> : null}
      </Grid>
      <Grid item container sx={{ height: CHART_CONTENT_HEIGHT }}>
        {displayState.showTableView ? (
          <TableViewMetrics
            tableData={tableViewData}
            fullScreenHandle={fullScreenHandle}
            chartHeight={CHART_CONTENT_HEIGHT}
          />
        ) : (
          <BarChart
            sx={chartStyles()}
            title={title?.[locale.selected]}
            xAxis={xAxisConfig ? [xAxisConfig] : undefined}
            yAxis={yAxisConfig}
            series={series}
            margin={{ right: 50, left: 50, top: 40 }}
            slotProps={{
              popper: { container: () => fullScreenHandle.node.current },
              legend: {
                hidden: !displayState.showLegend,
                direction: 'column',
                position: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                padding: {
                  top: 40,
                },
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 5,
                labelStyle: {
                  fontSize: 12,
                },
              },
              noDataOverlay: { message: t('common:customDashboard.noData') },
            }}
            barLabel={barLabelConfig}
            grid={{ vertical: displayGlobalState.showGridLines, horizontal: displayGlobalState.showGridLines }}
            rightAxis={(yAxisConfig?.[1]?.id as string) ?? undefined}
            tooltip={{ trigger: 'axis' }}
          />
        )}
      </Grid>
    </Grid>
  );
};

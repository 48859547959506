import { toSql } from 'pgsql-ast-parser/lib';
import { DataFieldWithDataType } from '../../types';
import { isHierarchical } from '../utils';
import { hierarchicalfieldToExpr, nonHierarchicalfieldToExpr } from './utils';

/**
 * Escapes a string for safe use in PostgreSQL string literals.
 * - Escapes single quotes
 * - Removes newlines and carriage returns completely as the backend parser doesn't support them
 *
 * @param str The string to escape
 * @returns The escaped string safe for use in PostgreSQL string literals
 */
export const escapeSqlString = (str: string): string => {
  if (str === null || str === undefined) {
    return '';
  }

  return str
    .replace(/'/g, "''") // Escape single quotes
    .replace(/[\n\r]/g, ''); // Remove newlines and carriage returns completely since the backend parser doesn't support them
};

export const fieldToSql = (dataField: DataFieldWithDataType): string => {
  return toSql.selectionColumn({
    expr: isHierarchical(dataField) ? hierarchicalfieldToExpr(dataField) : nonHierarchicalfieldToExpr(dataField),
  });
};

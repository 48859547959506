import { Grid } from '@mui/material';
import { LineSeriesType } from '@mui/x-charts';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UNDEFINED_DISPLAY_KEY } from '../../../../constants';
import { useGlobalLocaleContext } from '../../../../context/contexts';
import { DataFieldWithDataType } from '../../../../types';
import { CHART_CONTENT_HEIGHT, CHART_TITLE_HEIGHT } from '../../../styles';
import { benchmarkColor, chartColorsHex } from '../../../theme';
import { LineChartComponentConfig, OverTimeBarChartComponentConfig } from '../../dashboards/types';
import { formatHierarchicalLabel } from '../../filter/utils-display';
import { TableViewMetrics } from '../../tableview/TableViewMetrics';
import { ChartComponentProps } from '../chart-types';
import { ChartDataFetchError } from '../ChartDataFetchError';
import { ChartTitle } from '../ChartTitle';
import { LineChart } from '../mui-charts';
import { CustomAxisTooltip } from '../mui-charts/CustomAxisTooltip';
import { toMuiChartsOverTimeData } from '../mui-charts/utils-display';
import { GlobalTimePeriodDisplayAction } from '../timeperiod/types';
import { MarkWithLabel } from './CustomLabel';
import { useMetricQueries } from './hooks';
import { chartStyles } from './styles';
import { Tools } from './Tools';
import { ToolsAction } from './types';

type LineChartOverTimeProps = ChartComponentProps<
  LineChartComponentConfig | OverTimeBarChartComponentConfig,
  | 'manualDataRefetchCountHandle'
  | 'timeSliderState'
  | 'filtersState'
  | 'segmentationState'
  | 'showTools'
  | 'fullScreenHandle'
  | 'displayGlobalState'
>;

export const LineChartOverTime = (props: LineChartOverTimeProps) => {
  const {
    componentConfig,
    manualDataRefetchCountHandle,
    timeSliderState,
    filtersState,
    segmentationState,
    showTools,
    fullScreenHandle,
    displayGlobalState,
  } = props;

  const { title, queries, chartTypeConfig, colors } = componentConfig;
  const locale = useGlobalLocaleContext();
  const { t } = useTranslation();
  const formatLabel = (value: (string | null)[], dataField: DataFieldWithDataType) =>
    formatHierarchicalLabel(value, dataField, t(UNDEFINED_DISPLAY_KEY), t);

  const {
    data: { series, tableViewData, metricSql, xAxisConfig, yAxisConfig },
    displayHandle,
    metrics,
    filters,
    segmentations,
    timeSelection,
    error,
  } = useMetricQueries<LineSeriesType>(
    queries,
    timeSliderState,
    filtersState,
    segmentationState,
    manualDataRefetchCountHandle.current,
    chartTypeConfig,
    displayGlobalState,
    formatLabel,
    colors,
    t,
    toMuiChartsOverTimeData<LineSeriesType>
  );

  const [displayState, displayReducer] = displayHandle;

  useEffect(() => {
    const action: GlobalTimePeriodDisplayAction = {
      type: 'global-state-sync',
      globalDisplayState: displayGlobalState,
    };
    displayReducer(action);
  }, [displayGlobalState]);

  useEffect(() => {
    // This is necessary when segmentation is added by the user
    // resulting in a stacked bar chart
    const action: ToolsAction = {
      type: 'toggle-tools',
      segmentations,
    };
    displayReducer(action);
  }, [segmentationState.items.length]);

  if (error.length > 0 || !xAxisConfig || !yAxisConfig) {
    return <ChartDataFetchError manualDataRefetchCountHandle={manualDataRefetchCountHandle} />;
  }
  const styles = chartStyles({
    seriesStyling: queries.reduce((acc, q, i) => {
      const seriesId = series[i].id;
      if (seriesId) {
        acc[seriesId] = {
          // TODO: improve handling of benchmark through types
          dottedLine: q.isBenchmark ?? false,
          stroke: q.isBenchmark ? benchmarkColor : undefined,
        };
      }
      return acc;
    }, {} as Record<string, { dottedLine: boolean; stroke: string | undefined }>),
  });

  return (
    <Grid item container direction="column" sx={{ height: '100%' }}>
      <Grid item container sx={{ height: CHART_TITLE_HEIGHT }}>
        <ChartTitle
          metrics={metrics}
          metricsSql={metricSql}
          title={title?.[locale.selected]}
          timeSelection={timeSelection}
          segmentations={segmentations}
          userFilters={filters}
        />
        {showTools ? <Tools displayHandle={displayHandle} fullScreenHandle={fullScreenHandle} /> : null}
      </Grid>
      <Grid item container sx={{ height: CHART_CONTENT_HEIGHT }}>
        {displayState.showTableView ? (
          <TableViewMetrics
            tableData={tableViewData}
            fullScreenHandle={fullScreenHandle}
            chartHeight={CHART_CONTENT_HEIGHT}
          />
        ) : (
          <LineChart
            sx={styles}
            title={title?.[locale.selected]}
            xAxis={xAxisConfig}
            topAxis={
              xAxisConfig?.[1]?.id
                ? {
                    axisId: xAxisConfig?.[1]?.id as string | undefined,
                    tickLabelStyle: {
                      fill: queries[1]?.isBenchmark
                        ? benchmarkColor
                        : colors?.[xAxisConfig?.[1]?.id as number] ?? chartColorsHex[xAxisConfig?.[1]?.id as number],
                    },
                  }
                : undefined
            }
            yAxis={yAxisConfig}
            rightAxis={yAxisConfig?.[1]?.id as string | undefined}
            series={series.map((s) => ({ ...s, connectNulls: true }))}
            margin={{ right: 50, left: 50, top: 40 }}
            slotProps={{
              popper: { container: () => fullScreenHandle.node.current },
              legend: {
                hidden: !displayState.showLegend,
                direction: 'column',
                position: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                padding: {
                  top: 40,
                },
                itemMarkWidth: 20,
                itemMarkHeight: 2,
                markGap: 5,
                itemGap: 5,
                labelStyle: {
                  fontSize: 12,
                },
              },
              noDataOverlay: { message: t('common:customDashboard.noData') },
            }}
            grid={{ vertical: displayGlobalState.showGridLines, horizontal: displayGlobalState.showGridLines }}
            slots={{ mark: displayState.showLabels ? MarkWithLabel : undefined, popper: CustomAxisTooltip }}
            tooltip={{ trigger: 'axis' }}
          />
        )}
      </Grid>
    </Grid>
  );
};

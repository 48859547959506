import { Grid } from '@mui/material';
import { LineSeriesType } from '@mui/x-charts';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UNDEFINED_DISPLAY_KEY } from '../../../../constants';
import { useGlobalLocaleContext } from '../../../../context/contexts';
import { DataFieldWithDataType } from '../../../../types';
import { CHART_CONTENT_HEIGHT, CHART_TITLE_HEIGHT } from '../../../styles';
import { LineChartComponentConfig, OverTimeBarChartComponentConfig } from '../../dashboards/types';
import { formatHierarchicalLabel } from '../../filter/utils-display';
import { TableViewMetrics } from '../../tableview/TableViewMetrics';
import { ChartComponentProps } from '../chart-types';
import { ChartDataFetchError } from '../ChartDataFetchError';
import { ChartTitle } from '../ChartTitle';
import { LineChart } from '../mui-charts';
import { toMuiChartsOverTimeData } from '../mui-charts/utils-display';
import { MarkWithLabel } from './CustomLabel';
import { useMetricQueries } from './hooks';
import { Tools } from './Tools';
import { GlobalOverTimeDisplayAction, ToolsAction } from './types';
import { getSeriesStyles } from './utils';

type AreaChartOverTimeProps = ChartComponentProps<
  LineChartComponentConfig | OverTimeBarChartComponentConfig,
  | 'manualDataRefetchCountHandle'
  | 'timeSliderState'
  | 'filtersState'
  | 'segmentationState'
  | 'showTools'
  | 'fullScreenHandle'
  | 'displayGlobalState'
>;
export const AreaChartOverTime = (props: AreaChartOverTimeProps) => {
  const {
    componentConfig,
    manualDataRefetchCountHandle,
    timeSliderState,
    filtersState,
    segmentationState,
    showTools,
    fullScreenHandle,
    displayGlobalState,
  } = props;

  const { title, queries, chartTypeConfig, colors } = componentConfig;
  const locale = useGlobalLocaleContext();

  const { t } = useTranslation();
  const formatLabel = (value: (string | null)[], dataField: DataFieldWithDataType) =>
    formatHierarchicalLabel(value, dataField, t(UNDEFINED_DISPLAY_KEY), t);

  const {
    data: { series, tableViewData, metricSql, xAxisConfig, yAxisConfig },
    displayHandle,
    metrics,
    filters,
    segmentations,
    timeSelection,
    error,
  } = useMetricQueries<LineSeriesType>(
    queries,
    timeSliderState,
    filtersState,
    segmentationState,
    manualDataRefetchCountHandle.current,
    chartTypeConfig,
    displayGlobalState,
    formatLabel,
    colors,
    t,
    toMuiChartsOverTimeData<LineSeriesType>
  );

  const [displayState, displayReducer] = displayHandle;

  useEffect(() => {
    const action: GlobalOverTimeDisplayAction = {
      type: 'global-state-sync',
      globalDisplayState: displayGlobalState,
    };
    displayReducer(action);
  }, [displayGlobalState]);

  useEffect(() => {
    const action: ToolsAction = {
      type: 'toggle-tools',
      segmentations,
    };
    displayReducer(action);
  }, [segmentationState.items.length]);

  if (error.length > 0 || !xAxisConfig || !yAxisConfig) {
    return <ChartDataFetchError manualDataRefetchCountHandle={manualDataRefetchCountHandle} />;
  }

  const styles = getSeriesStyles(queries, series);

  return (
    <Grid item container direction="column" sx={{ height: '100%' }}>
      <Grid item container sx={{ height: CHART_TITLE_HEIGHT }}>
        <ChartTitle
          metrics={metrics}
          metricsSql={metricSql} // TODO: fix this
          title={title?.[locale.selected]}
          timeSelection={timeSelection}
          segmentations={segmentations}
          userFilters={filters}
        />
        {showTools ? <Tools displayHandle={displayHandle} fullScreenHandle={fullScreenHandle} /> : null}
      </Grid>
      <Grid item container sx={{ height: CHART_CONTENT_HEIGHT }}>
        {displayState.showTableView ? (
          <TableViewMetrics
            tableData={tableViewData}
            fullScreenHandle={fullScreenHandle}
            chartHeight={CHART_CONTENT_HEIGHT}
          />
        ) : (
          <LineChart
            sx={styles}
            title={title?.[locale.selected]}
            xAxis={xAxisConfig}
            yAxis={yAxisConfig}
            series={series.map((s) => ({ ...s, area: true, stack: 'total', connectNulls: true }))}
            margin={{ right: 80, left: 80, top: 80 }}
            slotProps={{
              popper: { container: () => fullScreenHandle.node.current },
              legend: { hidden: true },
              noDataOverlay: { message: t('common:customDashboard.noData') },
            }}
            grid={{ vertical: displayGlobalState.showGridLines, horizontal: displayGlobalState.showGridLines }}
            rightAxis={(yAxisConfig?.[1]?.id as string) ?? undefined}
            tooltip={{ trigger: 'axis' }}
            slots={{ mark: displayState.showLabels ? MarkWithLabel : undefined }}
          />
        )}
      </Grid>
    </Grid>
  );
};

import { parseFirst } from 'pgsql-ast-parser/lib';
import { Domains } from '../../constants';
import { escapeSqlString } from '../data/utils-sql';
import { BackendService } from '../service';
import { SQLQuery, VersionId } from '../types';
import { Employee } from './types';
import { employeeTypeDataMapper } from './utils';

export interface EmployeeService {
  search: (searchQuery: SQLQuery, version: VersionId | null) => Promise<Employee[]>;
}

export class BackendEmployeeService implements EmployeeService {
  constructor(readonly backendService: BackendService, readonly domain: Domains) {}

  public search = async (searchQuery: string, version: VersionId | null): Promise<Employee[]> => {
    if (searchQuery.length < 2) {
      return [];
    }

    const escapedSearchQuery = escapeSqlString(searchQuery);
    const queryString = `
      with similarity_result as (
        select
            greatest(
                case when employee.employeeId = '${escapedSearchQuery}' then 1 else 0 end,
                case when employee.companyEmail = '${escapedSearchQuery}' then 1 else 0 end,
                coalesce(similarity(employee.fullName, '${escapedSearchQuery}'), 0),
                coalesce(similarity(employee.localFullName, '${escapedSearchQuery}'), 0),
                coalesce(similarity(employee.localFullNamePronunciation, '${escapedSearchQuery}'), 0),
                coalesce(similarity(employee.preferredName, '${escapedSearchQuery}'), 0),
                coalesce(similarity(employee.localPreferredName, '${escapedSearchQuery}'), 0),
                coalesce(similarity(employee.localPreferredNamePronunciation, '${escapedSearchQuery}'), 0)
            ) as similarity,
          employee.employeeId,
          employee.companyEmail,
          employee.firstName,
          employee.lastName,
          employee.fullName,
          employee.localFullName,
          employee.localFirstName,
          employee.localLastName,
          employee.preferredName,
          employee.localPreferredName,
          employee.jobTitle
        from employee
        where
          ${version ? `employee.versionId = '${version}'` : ''}
          and employee.employmentTemporality in ('PAST', 'PRESENT')
          and employee.namespace = '${this.domain}'
        order by
          similarity desc
    ) select
      employeeId,
      companyEmail,
      firstName,
      lastName,
      fullName,
      localFullName,
      localFirstName,
      localLastName,
      preferredName,
      localPreferredName,
      jobTitle,
      similarity
    from similarity_result
    where similarity > 0.1
    limit 15;
  `;

    // Check that the SQL is valid
    // parsing adds parenthesis that are not accepted for whitelisted queries
    parseFirst(queryString);

    return this.backendService.fetchDataWhitelistedQuery(employeeTypeDataMapper)(queryString);
  };
}

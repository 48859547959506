import { Grid } from '@mui/material';
import { pieArcLabelClasses, PieSeriesType, PieValueType } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/internals';
import { useSuspenseQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { useDebounce } from 'use-debounce';
import { toRegularMetricResultOrError } from '../../../../api/utils';
import { TableViewMetrics } from '../../../../common/components/tableview/TableViewMetrics';
import { UNDEFINED_DISPLAY_KEY } from '../../../../constants';
import {
  useDisplayGlobalContext,
  useGlobalLocaleContext,
  useMetricDetailsMapContext,
  useMetricServiceContext,
} from '../../../../context/contexts';
import { RegularMetricIdType, TimeSelection } from '../../../../types';
import { formatMetricValue } from '../../../../utils-display';
import { CHART_CONTENT_HEIGHT, CHART_TITLE_HEIGHT } from '../../../styles';
import { chartColorsHex } from '../../../theme';
import { MetricTypes } from '../../../types';
import { getFilterConditions } from '../../../utils';
import { PieChartComponentConfig } from '../../dashboards/types';
import { formatHierarchicalLabel } from '../../filter/utils-display';
import { ChartComponentProps } from '../chart-types';
import { ChartDataFetchError } from '../ChartDataFetchError';
import { ChartTitle } from '../ChartTitle';
import { DonutChart } from '../mui-charts';
import { SegmentedSeries } from '../mui-charts/types';
import { toSingleLevelSegmentedTimePeriodChartInput } from '../mui-charts/utils';
import {
  timeSliderStateToTimePeriodTimeSelection,
  toPartialSingleLevelSegmentedChartDataTimePeriod,
  toSingleLevelSegmentedTableViewDataTimePeriod,
} from '../timeperiod/utils';
import { useDisplay } from './hooks';
import { Tools } from './Tools';
import { GlobalRadialDisplayAction } from './types';
const StyledDonutChart = styled(DonutChart)<{ $fullScreenActive: boolean; $chartHeight: string }>`
  self-center justify-self-center
  align-self: center;
  justify-self: center;
  height: ${(props) => (props.$fullScreenActive ? '90%' : props.$chartHeight)}
`;

type RadialTimePeriodProps = ChartComponentProps<
  PieChartComponentConfig,
  | 'manualDataRefetchCountHandle'
  | 'timeSliderState'
  | 'filtersState'
  | 'segmentationState'
  | 'showTools'
  | 'fullScreenHandle'
>;

const RadialTimePeriod = ({
  componentConfig,
  manualDataRefetchCountHandle,
  timeSliderState,
  filtersState,
  segmentationState,
  showTools,
  fullScreenHandle,
}: RadialTimePeriodProps) => {
  const { t } = useTranslation();
  const locale = useGlobalLocaleContext();
  const metricService = useMetricServiceContext();
  const displayHandle = useDisplay();
  const [displayState, displayReducer] = displayHandle;

  const { filters, metrics, segmentations, timeSelection } = componentConfig.query;
  const finalTimeSelection: TimeSelection = timeSliderState
    ? timeSliderStateToTimePeriodTimeSelection(timeSliderState)
    : timeSelection;
  const allFilters =
    [getFilterConditions(filtersState.items, MetricTypes.REGULAR, false), filters]
      .flatMap((f) => (f ? [f] : []))
      .join(' AND ') || undefined;

  const allSegmentations = segmentations; // mergeSegmentations(segmentations, toSegmentation(segmentationState.items), metrics, 2);

  const queryKey = useDebounce(
    JSON.stringify([
      'radial-time-period',
      metrics,
      finalTimeSelection,
      allFilters,
      allSegmentations,
      manualDataRefetchCountHandle.current,
    ]),
    500
  );

  const { data, error } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () =>
      toRegularMetricResultOrError(
        metricService.queryRegularMetrics,
        metrics,
        finalTimeSelection,
        allFilters,
        allSegmentations
      ),
  });

  const displayGlobalHandle = useDisplayGlobalContext();
  const [displayGlobalState] = displayGlobalHandle;

  useEffect(() => {
    const action: GlobalRadialDisplayAction = {
      type: 'global-state-sync',
      globalDisplayState: displayGlobalState,
    };
    displayReducer(action);
  }, [displayGlobalState]);

  const metricDetailsMap = useMetricDetailsMapContext();

  if (error) {
    return <ChartDataFetchError manualDataRefetchCountHandle={manualDataRefetchCountHandle} />;
  }

  const chartInput = toSingleLevelSegmentedTimePeriodChartInput(
    data ?? [],
    toPartialSingleLevelSegmentedChartDataTimePeriod
  );
  const categories = metrics; // TODO: fix this

  const categoryRadius = 100 / categories.length; // 5 => 20
  const series: MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>[] = chartInput.series.map(
    (s: SegmentedSeries, i) => {
      const outerRadius = 100 - i * categoryRadius; // (100-0), (100-20), (100-40), (100-60), (100-80)
      const innerRadius = componentConfig.chartTypeConfig.variant === 'donut' ? 50 : outerRadius - categoryRadius; // (100-20=80), (100-)
      const metric: RegularMetricIdType = metrics[i] ?? metrics[0];
      const metricDetails = metricDetailsMap[metric.value];
      const formatType = metricDetails?.formatType;
      return {
        type: 'pie',
        outerRadius: `${outerRadius}%`,
        innerRadius: `${innerRadius}%`,
        arcLabel: displayState.showLabels
          ? (el: { value: number }) => formatMetricValue(el.value, formatType)
          : undefined,
        valueFormatter: (el: { value: number }) => formatMetricValue(el.value, formatType),
        data: s.values.map((v, j) => {
          const value = match(v)
            .with(P.union(P.number, P.nullish), (n) => n)
            .otherwise(() => {
              throw new Error(`unexpected type for value v ${v}`);
            });
          const d = chartInput.xAxisData[j];
          const label = d
            ? formatHierarchicalLabel(d.value, d.dataField, t(UNDEFINED_DISPLAY_KEY), t)
            : t(UNDEFINED_DISPLAY_KEY);
          return {
            id: `${label}-${value ?? t(UNDEFINED_DISPLAY_KEY)}`,
            value: value ?? 0,
            label,
            color: componentConfig?.colors?.[i + j] ?? chartColorsHex[i + j],
          };
        }),
      };
    }
  );

  const tableViewData = toSingleLevelSegmentedTableViewDataTimePeriod(data ?? []);
  return (
    <Grid item container direction="column" sx={{ height: '100%' }}>
      <Grid item container sx={{ height: CHART_TITLE_HEIGHT }}>
        <ChartTitle
          metrics={metrics}
          timeSelection={finalTimeSelection}
          metricsSql={chartInput.series.map((s) => s.meta.metricSql)}
          title={componentConfig.title?.[locale.selected]}
          segmentations={segmentations}
          userFilters={filters}
        />
        {showTools ? <Tools fullScreenHandle={fullScreenHandle} displayHandle={displayHandle} /> : null}
      </Grid>
      <Grid item container sx={{ height: CHART_CONTENT_HEIGHT }}>
        {displayState.showTableView ? (
          <TableViewMetrics
            tableData={tableViewData}
            fullScreenHandle={fullScreenHandle}
            chartHeight={CHART_CONTENT_HEIGHT}
          />
        ) : series.length === 0 ? (
          t('common:customDashboard.noData')
        ) : (
          <StyledDonutChart
            slotProps={{
              popper: { container: () => fullScreenHandle.node.current },
              legend: {
                hidden: !displayState.showLegend,
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 10,
                labelStyle: {
                  fontSize: 12,
                },
              },
              noDataOverlay: { message: t('common:customDashboard.noData') },
            }}
            series={series}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
              },
            }}
            $fullScreenActive={fullScreenHandle.active}
            $chartHeight={'100%'}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default RadialTimePeriod;
